import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart, removefromcart } from "../Redux/Actions/cartActions";
import { toast } from "react-toastify";
import Toast from "./LoadingError/Toast";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 500,
};

const QuantityInput = (props) => {
  const dispatch = useDispatch();
  const { max_order } = props;

  const [currentValue, setCurrentValue] = useState(props.qty);
  useEffect(() => {
    setCurrentValue(props.qty);
    // console.log("props:", props);
  }, [props.qty]);

  const increment = async () => {
    if(currentValue==0){
      setCurrentValue(props?.qty)
    }
    console.log(
      "Incresing Current Value",
      currentValue + 1,
      "Product Id",
      props.productId
    );
    if (currentValue + 1 === 1) {
      console.log("----------------------------------------------------");
      toast.success("Item Added to Cart", ToastObjects);
    }
    if (max_order > 0)
      if (currentValue + 1 > max_order) {
        console.log("Max Order Reached");
        setCurrentValue(max_order);
        toast.success("Only max " + max_order + " Qty can Order", ToastObjects);
        return;
      }
    setCurrentValue(currentValue + 1);
    await dispatch(addToCart(props.productId, currentValue + 1, props.item));
  };

  const decrement = () => {
    console.log(
      "Decreasing Current Value",
      currentValue,
      "Product Id",
      props.productId
    );
    if (currentValue > 1) {
      setCurrentValue(currentValue - 1);
      dispatch(addToCart(props.productId, currentValue - 1));
    } else if (currentValue - 1 === 0) {
      setCurrentValue(currentValue - 1);
      dispatch(removefromcart(props.productId));
    }
  };

  return (
    <>
      {currentValue === 1 && <Toast />}
      {currentValue === 0 && props.renderAddButton && (
        <div className="quantity-input">
          <button
            className="quantity-button__modifier"
            onClick={(e) => increment()}
          >
            Add
          </button>
        </div>
      )}
      {(currentValue !== 0 || !props.renderAddButton) && (
        <div className="quantity-input">
          <button
            className="quantity-input__modifier quantity-input__modifier--left"
            onClick={(e) => decrement()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>

            {/* &mdash; */}
          </button>
          <input
            className="quantity-input__screen"
            type="text"
            value={currentValue == 0 ? props?.qty : currentValue}
            readOnly
          />
          <button
            className="quantity-input__modifier quantity-input__modifier--right"
            onClick={(e) => increment()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {/* &#xff0b; */}
          </button>
        </div>
      )}
    </>
  );
};

export default QuantityInput;
