import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "../components/Header";
import { sendOTPLogin, verifyOTPLogin } from "../Redux/Actions/userActions";
import MobileNav from "../components/MobileNav";

const LoginOtp = ({ location, history }) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const otpData = useSelector((state) => state.otp);
  const { error, loading, otpVerificationInfo, otpInfo } = otpData;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    //Checking whether user is logged in or not.
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const mobileHandler = (e) => {
    // e.preventDefault();
    setMobile(e);
    if (e.length === 11) {
      if (/^\d{11}$/.test(e)) {
        setShowOTP(true);
      } else {
        alert("Invalid Mobile Number");
      }
    } else setShowOTP(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(verifyOTPLogin(mobile, otp));
    // dispatch(login(mobile, password));
  };

  const handleOTP = (e) => {
    dispatch(sendOTPLogin(mobile));
  };

  // const handleCreateNewAccount = (e) => {
  //   localStorage.removeItem("user_type");
  //   history.push("/register");
  //   dispatch({ type: OTP_VERIFIED_FAIL, payload: null });
  // };

  return (
    <>
      <Header />
      <MobileNav />

      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        {otpInfo}
        {(localStorage.getItem("user_type") === "new" || error) && (
          <div className="no-mobile-found">
            <p>
              <Link
                to={redirect ? `/register?redirect=${redirect}` : "/register"}
              >
                Create New Account
              </Link>
            </p>
          </div>
        )}

        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <div className="mobile-otp-button">
            <input
              type="numeric"
              pattern="[0-9]*"
              maxLength={11}
              placeholder="Mobile"
              value={mobile}
              onChange={(e) => mobileHandler(e.target.value)}
              required
              inputMode="numeric"
              minLength={11}
            />
            {showOTP && (
              <button type="button" onClick={(e) => handleOTP()}>
                {!otpInfo && "Send OTP"}
                {otpInfo && "Send Again"}
              </button>
            )}
          </div>

          {showOTP && (
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              required
              inputMode="numeric"
            />
          )}
          <button type="submit">Verify & Login</button>
          <p>
            <Link className="Login-a" to={`/login`}>
              Login Using Password
            </Link>
          </p>
          <p>
            <Link
              to={redirect ? `/register?redirect=${redirect}` : "/register"}
            >
              Create New Account
            </Link>
          </p>
          <p className="privacy-policy-links">
            By continuing, you agree to our{" "}
            <Link to="/term-condition"> Terms of service </Link> &{" "}
            <Link to="/policy">Privacy policy </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default LoginOtp;
