import {
  SUBSCRIBER_REQUEST,
  SUBSCRIBER_REQUEST_FAIL,
  SUBSCRIBER_REQUEST_SUCCESS,
} from "../Constants/GlobalConstant";

export const subscriberReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBER_REQUEST:
      return { loading: true };
    case SUBSCRIBER_REQUEST_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case SUBSCRIBER_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
