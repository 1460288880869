import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscribe } from "../../Redux/Actions/GeneralActions";

const CalltoActionSection = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const subscribeData = useSelector((state) => state.subscriber);
  const { error, loading, data } = subscribeData;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(subscribe(email));
    setEmail("");
  };

  useEffect(() => {
    // console.log("your data", data);
  }, [error, loading, data]);
  return (
    <div className="subscribe-section bg-with-black">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="subscribe-head">
              <h2>Do you need more tips?</h2>
              <p>Sign up free and get the latest tips.</p>
              <form className="form-section">
              {error && <span className="subscriber-error">{error}</span>}
              {!error && <span className="subscriber-success">{data}</span>}
                <input
                  placeholder="Your Email..."
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  value="Yes. I want!"
                  name="subscribe"
                  type="submit"
                  onClick={submitHandler}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalltoActionSection;
