import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import { register, sendOTP, verifyOTP } from "../Redux/Actions/userActions";
import Header from "./../components/Header";
import MobileNav from "../components/MobileNav";

const Register = ({ location, history, match }) => {
  // window.scrollTo(0, 0);
  const refId = match.params.refId;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password] = useState("default");
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [ref_id, setRefId] = useState(refId ? refId : null);
  const [showOTP, setShowOTP] = useState(false);
  const [showOTPLoader, setShowOTPLoader] = useState(false);
  const [otpError, setOTPError] = useState(null);

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/cart";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  const otpData = useSelector((state) => state.otp);
  const { otpInfo, otpVerificationInfo } = otpData;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  useEffect(() => {
    if (otpVerificationInfo) {
      dispatch(register(name, mobile, email, password));
    }
    if (otpData.loading) setShowOTPLoader(otpData.loading);

    if (otpData.error) {
      setOTPError(otpData.error);
      setShowOTPLoader(false);
    }
   }, [dispatch, otpVerificationInfo, otpData, email, mobile, name, password]);

  const submitHandler = async (e) => {
    e.preventDefault();
    await dispatch(verifyOTP(mobile, otp));
    if (otpVerificationInfo) {
      dispatch(register(name, mobile, email, password, ref_id));
      history.push("/cart");
    }
  };

  const mobileHandler = (e) => {
    // e.preventDefault();
    if (e.length > 11) return false;
    setMobile(e);
    if (e.length === 11) {
      setOTPError(null);
      if (/^\d{11}$/.test(e)) {
        setShowOTP(true);
      } else {
        alert("Invalid Mobile Number");
      }
    } else setShowOTP(false);
  };

  const handleOTP = (e) => {
    setShowOTPLoader(false);
    dispatch(sendOTP(mobile, mobile ? null : email));
  };

  return (
    <>
      <Header />
      <MobileNav />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {(error || otpError) && (
          <Message variant="alert-danger">{error ? error : otpError}</Message>
        )}
        {(loading || showOTPLoader) && !otpInfo && <Loading />}
        {otpInfo}
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <div className="mobile-otp-button">
            <input
              type="text"
              pattern="[0-9]*"
              placeholder="Mobile"
              value={mobile}
              onChange={(e) => mobileHandler(e.target.value)}
              required
              inputMode="numeric"
            />
            {showOTP && (
              <button type="button" onClick={(e) => handleOTP()}>
                {!otpInfo && "Send OTP"}
                {otpInfo && "Send Again"}
              </button>
            )}
          </div>

          {showOTP && (
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              inputMode="numeric"
              required
            />
          )}
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <input
            type="email"
            placeholder="Email (Optional)"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="string"
            placeholder="Reference (Optional)"
            value={ref_id}
            onChange={(e) => setRefId(e.target.value)}
            disabled={true}
          />

          {/* <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          /> */}

          <button type="submit">
            {" "}
            {!showOTP && "Register"} {showOTP && "Verify & Submit"}
          </button>
          <p>
            <Link to={redirect ? `/login-otp` : "/login-otp"}>
              I Have Account <strong>Login</strong>
            </Link>
          </p>
          <p className="privacy-policy-links">
            By continuing, you agree to our{" "}
            <Link to="/term-condition"> Terms of service </Link> &{" "}
            <Link to="/policy">Privacy policy </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default Register;
