import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";
import {
  CODOrder,
  UPIOrder,
  getOrderDetails,
  payOrder,
} from "../Redux/Actions/OrderActions";
import Loading from "../components/LoadingError/Loading";
import Message from "../components/LoadingError/Error";
import moment from "moment";
import { ORDER_PAY_RESET } from "../Redux/Constants/OrderConstants";
import {
  BASE_URL,
  DEFAULT_CURRENCY,
  IMAGE_BASE_URL,
  IMAGE_CACHE_URL,
} from "../Redux/Constants/GlobalConstant";
import RenderRazorpay from "../components/payments/RenderRazorpay";
import MobileNav from "../components/MobileNav";
import axios from "axios";

const OrderScreen = ({ match }) => {
  // window.scrollTo(0, 0);
  const [sdkReady, setSdkReady] = useState(false);
  const orderId = match.params.id;
  const dispatch = useDispatch();

  const razorOrder = useSelector((state) => state.orderUPI);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const orderPay = useSelector((state) => state.orderPay);
  const { success: successPay } = orderPay;
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [rozarLoading, setRozarLoading] = useState(false);
  const [codLoading] = useState(false);

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );

    order.itemsMrpPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.mrp_price * item.qty, 0)
    );
  }

  useEffect(() => {
    console.log("Refresing Order Screen", 2, successPay, order);
    if(successPay){
      
     window.location.reload();
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get(
        BASE_URL + "/api/config/paypal"
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://sandbox.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (orderId && !order) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order?.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, orderId, successPay, order]);

  useEffect(() => {
    if (displayRazorpay) setDisplayRazorpay(false);
  }, [displayRazorpay]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  const successCODHandler = (paymentResult) => {
    dispatch(CODOrder(orderId, paymentResult));
  };

  const successUPIHandler = async (paymentResult) => {
    setRozarLoading(true);
    const payload = {
      amount: parseFloat(order.itemsPrice) + parseFloat(order.shippingPrice),
      currency: "GPR",
      receipt: order._id,
    };

    // console.log("UPI Payload on Razor pay", payload, order);
    await dispatch(UPIOrder(orderId, payload));

    // setRozarOrderId(razorOrder.order.id)
    // console.log("Agrocroft Order Detail: ------>", order);
    // console.log("Razor Order Detail: ------>", orderDetails);
    setDisplayRazorpay(true);
    setRozarLoading(false);
  };

  return (
    <>
      <Header />
      <MobileNav />
      <div className="container">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            {order.reward_value > 0 && order.isPaid && (
              <div className="">
                Congratulation, {DEFAULT_CURRENCY} <b>{order.reward_value} </b>{" "}
                Cash Point will be added to your Account after Delivery.
              </div>
            )}
            {order.reward_value > 0 && order.isCOD && (
              <div className="">
                Congratulation, {DEFAULT_CURRENCY} <b>{order.reward_value} </b>{" "}
                Cash Point will be added to your Account after Delivery.
              </div>
            )}

            <div className="row  order-detail desktop-cart-view">
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row ">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Customer</strong>
                    </h5>
                    <p>{order.user.name}</p>
                    <p>
                      <a href={`mailto:${order.user.email}`}>
                        {order.user.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>
                      <strong>Shipping:</strong> {order.shippingAddress.city}
                    </p>
                    <p>
                      <strong>Pay method: </strong>
                      {order.paymentMethod}
                    </p>

                    {order.isPaid && !order.isDelivered && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Paid on {moment(order.paidAt).calendar()}
                        </p>
                      </div>
                    )}
                    {order.isCOD && !order.isDelivered && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Cash on Delivery
                        </p>
                      </div>
                    )}
                    {order.isDelivered && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Delivered
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Deliver to</strong>
                    </h5>
                    <p>
                      Address: {order.shippingAddress.city},{" "}
                      {order.shippingAddress.address},{" "}
                      {order.shippingAddress.postalCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  order-detail mobile-cart-view">
              <div className="col-lg-4 col-sm-4 mb-lg-0 mb-0 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>
                      <strong>Shipping: </strong>
                      {order.shippingAddress.city}
                    </p>
                    <p>
                      <strong>Pay Method: </strong>{" "}
                      {order?.paymentMethod ? order.paymentMethod : "UPI"}
                    </p>

                    {order.isPaid && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Paid on {moment(order.paidAt).calendar()}
                        </p>
                      </div>
                    )}
                    {order.isCOD && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Cash on Delivery
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row order-products justify-content-between">
              <div className="col-lg-8">
                {order.orderItems.length === 0 ? (
                  <Message variant="alert-info mt-5">
                    Your order is empty
                  </Message>
                ) : (
                  <>
                    {order.orderItems.map((item, index) => (
                      <div key={index}>
                        <div
                          className="order-product row desktop-cart-view"
                          key={index}
                        >
                          <div className="col-md-3 col-6">
                            <img
                              src={IMAGE_CACHE_URL + "/" + item.image}
                              alt={item.name}
                            />
                          </div>
                          <div className="col-md-5 col-6 d-flex align-items-center">
                            <Link to={`/products/${item.product}`}>
                              <h6>{item.name}</h6>
                            </Link>
                          </div>
                          <div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center ">
                            <h4>QUANTITY</h4>
                            <h6>{item.qty}</h6>
                          </div>
                          <div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end  d-flex flex-column justify-content-center ">
                            <h4>SUBTOTAL</h4>
                            <h6>
                              {DEFAULT_CURRENCY} {item.qty * item.price}
                            </h6>
                          </div>
                        </div>
                        <div
                          className="order-product row mobile-cart-view"
                          key={index + 1}
                        >
                          <div className="cart-image-name-together">
                            <img
                              src={IMAGE_BASE_URL + "/" + item.image}
                              alt={item.name}
                            />
                            <Link to={`/products/${item.product}`}>
                              <h4>{item.name}</h4>
                            </Link>
                          </div>
                          <div className="cart-price cart-price-total">
                            <h4>QUANTITY</h4>
                            <h6 className="po-item-qty">{item.qty}</h6>
                          </div>
                          <div className="cart-price cart-price-total">
                            <h4>SUBTOTAL</h4>
                            <h6 className="po-item-qty">
                              {DEFAULT_CURRENCY} {item.qty * item.price}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {/* total */}
              <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
                <table className="table table-bordered">
                  <tbody>
                    {order.itemsMrpPrice !== order.itemsPrice && (
                      <tr>
                        <td>
                          <strong>MRP Total</strong>
                        </td>
                        <td className="text-align-right">
                          {DEFAULT_CURRENCY} {order.itemsMrpPrice}
                        </td>
                      </tr>
                    )}
                    {order.itemsMrpPrice !== order.itemsPrice && (
                      <tr>
                        <td>
                          <strong>Total Saving</strong>
                        </td>
                        <td className="text-align-right">
                          {DEFAULT_CURRENCY}{" "}
                          {order.itemsMrpPrice - order.itemsPrice}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <strong>Our Price</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {order.itemsPrice}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Shipping</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {order.shippingPrice}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tax</strong>
                      </td>
                      <td className="text-align-right">
                        {order.taxPrice <= 0
                          ? "Included"
                          : { DEFAULT_CURRENCY } + " " + order.taxPrice}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {order.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {displayRazorpay && (
                  <RenderRazorpay
                    amount={order.totalPrice}
                    currency={"INR"}
                    orderId={razorOrder.order.id}
                    orderId2={orderId}
                    // keyId={"rzp_test_6WZZqEVYuD3cXE"}
                    // keySecret={"YOIQyXMb3IbNjAsbfzvXqLTv"}
                    keyId={"rzp_live_HVf2D5e8LI87HC"}
                    keySecret={"IgqP4YfusH9wctRZ0JdySp8A"}
                    orderDetail={order}
                  />
                )}
                {/* UPI Button */}
                {!order.isCOD &&
                  !order.isPaid &&
                  order.paymentMethod === "UPI" && (
                    <div className="col-12">
                      {/* {rozarLoading && <Loading />} */}
                      {!rozarLoading && (
                        <button
                          onClick={successUPIHandler}
                          className="round-black-btn"
                        >
                          UPI
                        </button>
                      )}
                    </div>
                  )}
                {/* Paypal Button */}
                {!order.isPaid &&
                  !order.isCOD &&
                  order.paymentMethod === "Paypal" && (
                    <div className="col-12">
                      {/* {loadingPay && <Loading />} */}
                      {!sdkReady ? (
                        <Loading />
                      ) : (
                        <PayPalButton
                          amount={order.totalPrice}
                          onSuccess={successPaymentHandler}
                        />
                      )}
                    </div>
                  )}
                {/* COD Button */}
                {!order.isCOD &&
                  !order.isPaid &&
                  order.paymentMethod === "COD" && (
                    <div className="col-12">
                      {codLoading && <Loading />}
                      {!codLoading && (
                        <button
                          onClick={successCODHandler}
                          className="round-black-btn"
                        >
                          Cash of Delivery
                        </button>
                      )}
                    </div>
                  )}
              </div>
            </div>

            <div className="row  order-detail order-mobile-cart-view">
              <div className="col-lg-4 col-sm-4 mb-lg-0 mb-5 mb-sm-0">
                <div className="row ">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Customer</strong>
                    </h5>
                    <p>{order.user.name}</p>
                    <p>
                      <a href={`mailto:${order.user.email}`}>
                        {order.user.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* 2 */}
              {/* <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>Shipping: {order.shippingAddress.country}</p>
                    <p>Pay method: {order.paymentMethod}</p>

                    {order.isPaid && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Paid on {moment(order.paidAt).calendar()}
                        </p>
                      </div>
                    )}
                    {order.isCOD && (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Cash on Delivery
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              {/* 3 */}
              <div className="col-lg-4 col-sm-4 mb-lg-0 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Deliver to</strong>
                    </h5>
                    <p>
                      Address: {order.shippingAddress.city},{" "}
                      {order.shippingAddress.address},{" "}
                      {order.shippingAddress.postalCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderScreen;
