import {
  BASE_URL,
  SUBSCRIBER_REQUEST,
  SUBSCRIBER_REQUEST_FAIL,
  SUBSCRIBER_REQUEST_SUCCESS,
} from "../Constants/GlobalConstant";

import axios from "axios";

// LOGIN
export const subscribe = (email) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      BASE_URL + `/api/general/subscribe`,
      { email },
      config
    );
    dispatch({ type: SUBSCRIBER_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUBSCRIBER_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
