import React from "react";
import { DEFAULT_CALLER_NO, DEFAULT_OFFICE_ADDRESS, DEFAULT_WHATSAPP } from "../../Redux/Constants/GlobalConstant";
 
const ContactInfo = () => {
  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>Call Us 8 Am to 6 Pm</h5>
            <a href="tel:+4407876128289">{DEFAULT_CALLER_NO}</a>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>Headquarter</h5>
            <p>{DEFAULT_OFFICE_ADDRESS}</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-fax"></i>
            </div>
            <h5>Whatsapp</h5>
            <a href="https://wa.me/message/DMXMJTYTRX4YJ1" target="_blank" rel="noreferrer">
              {DEFAULT_WHATSAPP}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
