import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import Rating from "../components/homeComponents/Rating";
import { Link } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductReview,
  listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "../components/LoadingError/Loading";
import { PRODUCT_CREATE_REVIEW_RESET } from "../Redux/Constants/ProductConstants";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import QuantityInput from "../components/QuantityInput";
import { DEFAULT_CURRENCY, IMAGE_CACHE_URL } from "../Redux/Constants/GlobalConstant";
import MobileNav from "../components/MobileNav";

const SingleProduct = ({ history, match }) => {
  const [qty] = useState(0);
  // const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  // const [productData, setProductData] = useState([]);
  // const [unit, setUnit] = useState("Nos");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const productId = match.params.id;
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingCreateReview,
    error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successCreateReview) {
      alert("Review Submitted");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    if (productId) dispatch(listProductDetails(productId));
  }, [dispatch, productId, successCreateReview]);

  useEffect(() => {
    // if (!product?._id) setProductData(product);
    if (product?._id) {
      if (product?.images) {
        let temp = [];
        product?.images.forEach((element, i) => {
          if (product?.image !== element && i === 0) {
            temp.push(product?.image);
            temp.push(element);
          } else {
            temp.push(element);
          }
        });
        product.images = temp;
      }
      product?.attribute_collection.forEach((ele) => {
        if (ele?.title === "Description") setDescription(ele.value);
        // if (ele?.title === "Unit") setUnit(ele.value);
        if (ele?.value === "Type") setType(ele.title);
      });
    }
  }, [productId, product]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    );
  };
  return (
    <>
      <Header />
      <MobileNav />

      <div className="container single-product">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              {/* //Image Section */}
              <div className="col-md-4">
                <div className="">
                  <Carousel
                    autoPlay="true"
                    infiniteLoop="true"
                    showIndicators="true"
                    thumbWidth="15%"
                  >
                    {product.images?.map((image, i) => (
                      <div key={i}>
                        <img
                          src={IMAGE_CACHE_URL + image}
                          alt={image}
                        />
                        {/* <p className="legend">{i}</p> */}
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
              {/* // Product Overview */}
              <div className="col-md-5">
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">{product.name}</div>
                  </div>
                  <p>
                    {!description &&
                      "Get this product, " +
                        product.name +
                        " to your doorstep at the earlist from store near yours."}
                    {description && description}
                  </p>

                  <div className="product-count col-lg-7 ">
                    {type && (
                      <div className="flex-box d-flex justify-content-between align-items-center">
                        <h6>Type</h6>
                        <span>{type}</span>
                      </div>
                    )}
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Price</h6>

                      <span>
                        {" "}
                        <span className="text-overline">
                          {DEFAULT_CURRENCY} {product.mrp_price}
                        </span>{" "}
                        {DEFAULT_CURRENCY} {product.price}
                      </span>
                    </div>
                    {product.unit && (
                      <div className="flex-box d-flex justify-content-between align-items-center">
                        <h6>Unit</h6>
                        <span>{product.unit}</span>
                      </div>
                    )}

                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Reviews</h6>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                      />
                    </div>
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Quantity</h6>
                      <QuantityInput
                        productId={product._id}
                        renderAddButton={true}
                        qty={qty}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* // About Agrocroft */}
              <div className="col-md-3">
                <h2>Why Agrocroft</h2>
                <div className="alert sp-about-agrocroft mt-3">
                  <h4>Instant Delivery</h4>
                  <p>
                    Get your essentials delivered to your doorstep swiftly and
                    reliably, saving you time and hassle.
                  </p>
                </div>
                <div className="alert sp-about-agrocroft mt-3">
                  <h4>Best Price & Offers</h4>
                  <p>
                    Enjoy unbeatable prices and exclusive offers on all your
                    favorite products. Save more today!
                  </p>
                </div>
                <div className="alert sp-about-agrocroft mt-3">
                  <h4>Wide Range & Unbeatable</h4>
                  <p>
                    Discover an extensive selection of products with unbeatable
                    quality and prices. Shop now!
                  </p>
                </div>{" "}
              </div>
            </div>

            {/* RATING */}
            <div className="row my-5">
              <div className="col-md-8">
                {product?.reviews?.length !== 0 && (
                  <div className="row">
                    <h6 className="mb-3">REVIEWS</h6>
                    {product?.reviews?.length === 0 && (
                      <Message variant={"alert-info mt-3"}>No Reviews</Message>
                    )}
                    {product?.reviews?.map((review,index) => (
                      <div
                        key={index}
                        className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded"
                      >
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <span>{moment(review.createdAt).calendar()}</span>
                        <div className="alert alert-info mt-3">
                          {review.comment}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="row">
                  <h5>Additional Information</h5>
                  {product?.attribute_collection?.map((list) => (
                    <ul className="att">
                      <li className="att_listing">
                        <span className="att_heading">{list.title}</span>
                        <span className="att_heading_value">{list.value}</span>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              <div className="col-md-4">
                <h6>WRITE A CUSTOMER REVIEW</h6>
                <div className="my-4">
                  {loadingCreateReview && <Loading />}
                  {errorCreateReview && (
                    <Message variant="alert-danger">
                      {errorCreateReview}
                    </Message>
                  )}
                </div>
                {userInfo ? (
                  <form onSubmit={submitHandler}>
                    <div className="my-4">
                      <strong>Rating</strong>
                      <select
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      >
                        <option value="">Select...</option>
                        <option value="1">1 - Poor</option>
                        <option value="2">2 - Fair</option>
                        <option value="3">3 - Good</option>
                        <option value="4">4 - Very Good</option>
                        <option value="5">5 - Excellent</option>
                      </select>
                    </div>
                    <div className="my-4">
                      <strong>Comment</strong>
                      <textarea
                        row="3"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button
                        disabled={loadingCreateReview}
                        className="col-12 bg-black border-0 p-3 rounded text-white"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="my-3">
                    <Message variant={"alert-warning"}>
                      Please{" "}
                      <Link to="/login-otp">
                        " <strong>Login</strong> "
                      </Link>{" "}
                      to write a review{" "}
                    </Message>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleProduct;
