import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createOrder, getOrderDetails } from "../Redux/Actions/OrderActions";
import { ORDER_CREATE_RESET } from "../Redux/Constants/OrderConstants";
import Header from "./../components/Header";
import Message from "./../components/LoadingError/Error";
import {
  DEFAULT_CURRENCY,
  IMAGE_BASE_URL,
  IMAGE_CACHE_URL,
} from "../Redux/Constants/GlobalConstant";
import MobileNav from "../components/MobileNav";
import Loading from "../components/LoadingError/Loading";

const PlaceOrderScreen = ({ history }) => {
  // window.scrollTo(0, 0);

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Calculate Price
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.itemsMrpPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.mrp_price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 9 : 9);
  cart.taxPrice = addDecimals(Number((0.0 * cart.itemsPrice).toFixed(2)));
  cart.tti_credits = addDecimals(Number((0.05 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, loading, error } = orderCreate;

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch(getOrderDetails(order._id));
    }
  }, [history, dispatch, success, order]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        isCOD: cart?.paymentMethod === "cod" ? true : false,
      })
    );
  };

  return (
    <>
      <Header />
      <MobileNav />
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <>
          <div className="container">
            <div className="row  order-detail desktop-cart-view">
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row ">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Customer</strong>
                    </h5>
                    <p>{userInfo?.name}</p>
                    <p>{userInfo?.email}</p>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>
                      Shipping:<strong> {cart?.shippingAddress.city} </strong>
                    </p>
                    <p>
                      Pay method:<strong> {cart?.paymentMethod} </strong>
                    </p>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Deliver to</strong>
                    </h5>
                    <p>
                      {cart?.shippingAddress.city},{" "}
                      {cart?.shippingAddress.address},{" "}
                      {cart?.shippingAddress.postalCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row order-products justify-content-between">
              <div className="col-lg-8">
                {cart.cartItems.length === 0 ? (
                  <Message variant="alert-info mt-5">
                    Your cart is empty
                  </Message>
                ) : (
                  <>
                    {cart.cartItems.map((item, index) => (
                      <div key={index}>
                        <div className="order-product row desktop-cart-view ">
                          <div className="col-md-3 col-6">
                            <img
                              // src={IMAGE_BASE_URL + "/" + item.image}
                              src={IMAGE_CACHE_URL + item.image}
                              alt={item.name}
                            />
                          </div>
                          <div className="col-md-5 col-6 d-flex align-items-center">
                            <Link to={`/products/${item.product}`}>
                              <h6>{item.name}</h6>
                            </Link>
                          </div>
                          <div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center ">
                            <h4>QUANTITY</h4>
                            <h6>{item.qty}</h6>
                          </div>
                          <div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end  d-flex flex-column justify-content-center ">
                            <h4>SUBTOTAL</h4>
                            <h6>
                              {item.mrp_price > item.price && (
                                <span className="text-overline">
                                  {DEFAULT_CURRENCY}
                                  {item.mrp_price * item.qty}
                                </span>
                              )}
                              {DEFAULT_CURRENCY}
                              {item.price * item.qty}
                            </h6>
                          </div>
                        </div>
                        <div
                          className="order-product cart-item row mobile-cart-view "
                          key={index}
                        >
                          <div className="cart-image-name-together">
                            <img
                              src={IMAGE_BASE_URL + "/" + item.image}
                              alt={item.name}
                            />
                            <Link to={`/products/${item.product}`}>
                              <h4>{item.name}</h4>
                            </Link>
                          </div>
                          <div className="cart-price cart-price-total">
                            <h4>QUANTITY</h4>
                            <h6 className="po-item-qty">{item.qty}</h6>
                          </div>
                          <div className="cart-price cart-price-total">
                            <h4>SUBTOTAL</h4>
                            <h6 className="po-item-qty">
                              {item.mrp_price !== item.price && (
                                <span className="text-overline">
                                  {DEFAULT_CURRENCY}
                                  {item.qty * item.mrp_price}
                                </span>
                              )}
                              {DEFAULT_CURRENCY} {item.qty * item.price}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {/* total */}
              <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
                {error && (
                  <div className="col-12">
                    <Message variant="alert-danger">{error}</Message>
                  </div>
                )}
                <table className="table table-bordered">
                  <tbody>
                    {cart.itemsMrpPrice !== cart.itemsPrice && (
                      <tr>
                        <td>
                          <strong>MRP Total</strong>
                        </td>
                        <td className="text-align-right">
                          {DEFAULT_CURRENCY} {cart.itemsMrpPrice}
                        </td>
                      </tr>
                    )}
                    {cart.itemsMrpPrice !== cart.itemsPrice && (
                      <tr>
                        <td>
                          <strong>Total Saving</strong>
                        </td>
                        <td className="text-align-right">
                          {DEFAULT_CURRENCY}{" "}
                          {cart.itemsMrpPrice - cart.itemsPrice}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <strong>Our Price</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {cart.itemsPrice}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Shipping</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {cart.shippingPrice}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td className="text-align-right">
                        {DEFAULT_CURRENCY} {cart.totalPrice}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {cart.cartItems.length === 0 ? null : (
                  <button type="submit" onClick={placeOrderHandler}>
                    PLACE ORDER
                  </button>
                )}

                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="text-align-right">
                        <strong>Reward Cash Points</strong>
                      </td>
                      <td>
                        {DEFAULT_CURRENCY} {cart.tti_credits}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row  order-detail mobile-cart-view-2">
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row ">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Customer</strong>
                    </h5>
                    <p>{userInfo.name}</p>
                    <p>{userInfo.email}</p>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>
                      <strong>Shipping:</strong> {cart.shippingAddress.city}
                    </p>
                    <p>
                      <strong>Pay method:</strong> {cart.paymentMethod}
                    </p>
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Deliver to</strong>
                    </h5>
                    <p>
                      Address: {cart.shippingAddress.city},{" "}
                      {cart.shippingAddress.address},{" "}
                      {cart.shippingAddress.postalCode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlaceOrderScreen;
