import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p className="att_heading_value">*Minimum Delivery Time is 1 Hrs, it depends. Any Order after 4 pm will be delivered next day. Cash on Delivery will be Done after call verfication.</p>
      <div className="justify-content-center d-flex">
        <div className="copyright-content">
        </div>
        <div className="card-name">
          <img
            alt="mastercard"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="visa"
            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
