import React, { useEffect } from "react";
import Header from "./../components/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "./../Redux/Actions/cartActions";
import {
  DEFAULT_CURRENCY,
  IMAGE_BASE_URL,
  IMAGE_CACHE_URL,
} from "../Redux/Constants/GlobalConstant";
import QuantityInput from "../components/QuantityInput";
import BestDealSection from "../components/homeComponents/BestDealSection";
import MobileNav from "../components/MobileNav";

const CartScreen = ({ match, location, history }) => {
  // window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);
  const mrpTotal = cartItems
    .reduce((a, i) => a + i.qty * i.mrp_price, 0)
    .toFixed(2);

  useEffect(() => {
    // console.log(cartItems);
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty, cartItems]);

  const checkOutHandler = () => {
    history.push("/login-otp?redirect=shipping");
  };

  // const removeFromCartHandle = (id) => {
  //   dispatch(removefromcart(id));
  // };
  return (
    <>
      <Header />
      <MobileNav />
      {/* Cart */}
      <div className="container">
        {cartItems.length === 0 ? (
          <div className=" alert alert-info text-center mt-3">
            Your cart is empty
            <Link
              className="btn btn-success mx-5 px-5 py-3"
              to="/"
              style={{
                fontSize: "12px",
              }}
            >
              SHOPPING NOW
            </Link>
          </div>
        ) : (
          <>
            <div className=" alert alert-info text-center mt-3">
              Total Cart Products
              <Link className="text-success mx-2" to="/cart">
                ({cartItems.length})
              </Link>
            </div>
            {/* cartiterm */}
            {cartItems.map((item, i) => (
              <div key={i}>
                <div className="cart-item row desktop-cart-view ">
                  {/* <div
                    onClick={() => removeFromCartHandle(item.product)}
                    className="remove-button d-flex justify-content-center align-items-center"
                  >
                    <i className="fas fa-times"></i>
                  </div> */}
                  <div className="cart-image col-md-2">
                    <img
                      // src={IMAGE_BASE_URL + "/" + item.image}
                      src={IMAGE_CACHE_URL + item.image}
                      alt={item.name}
                    />
                  </div>
                  <div className="cart-text col-md-4 d-flex align-items-center">
                    <Link to={`/products/${item.product}`}>
                      <h4>{item.name}</h4>
                    </Link>
                  </div>
                  <div className="cart-qty col-md-2 col-sm-4 mt-md-4 mt-4 mt-md-0 d-flex flex-column justify-content-center">
                    <QuantityInput
                      productId={item.product}
                      renderAddButton={false}
                      qty={item.qty}
                      max_order={item?.max_order ? item?.max_order : null}
                    />
                  </div>
                  <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start  d-flex flex-column justify-content-center col-sm-7 ">
                    <h6 className="cart-mrp-price-content">Our Price</h6>
                    <h4 className="cart-mrp-price-content">
                      {item.mrp_price !== item.price && (
                        <span className="text-overline">
                          {DEFAULT_CURRENCY}
                          {item.mrp_price}
                        </span>
                      )}
                      {DEFAULT_CURRENCY}
                      {item.price}
                    </h4>
                  </div>
                  <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start  d-flex flex-column justify-content-center col-sm-7">
                    <h6 className="cart-mrp-price-content">PRICE</h6>
                    <h4 className="cart-mrp-price-content">
                      {item.qty} * {DEFAULT_CURRENCY} {item.price}
                    </h4>
                  </div>
                </div>

                <div className="cart-item mobile-cart-view ">
                  <div className="cart-image-name-together">
                    <img
                      src={IMAGE_BASE_URL + "/" + item.image}
                      alt={item.name}
                    />
                    <Link to={`/products/${item.product}`}>
                      <h4>{item.name}</h4>
                    </Link>
                  </div>

                  <div className="cart-qty ">
                    <QuantityInput
                      productId={item.product}
                      renderAddButton={false}
                      qty={item.qty}
                    />
                  </div>
                  <div className="cart-price  ">
                    <h6 className="cart-mrp-price-content">Our Price</h6>
                    <h4 className="cart-mrp-price-content">
                      <span className="text-overline">
                        {DEFAULT_CURRENCY}
                        {item.mrp_price}
                      </span>{" "}
                      {DEFAULT_CURRENCY}
                      {item.price}
                    </h4>
                  </div>
                  <div className="cart-price cart-price-total">
                    <h6 className="cart-mrp-price-content">PRICE</h6>
                    <h4 className="cart-mrp-price-content">
                      {item.qty} * {DEFAULT_CURRENCY} {item.price}
                    </h4>
                  </div>
                </div>
              </div>
            ))}

            {/* End of cart iterms */}
            <div className="total-saving">
              <span className="sub">Total Saving:</span>
              <span className="total-price">
                {DEFAULT_CURRENCY} {mrpTotal - total}
              </span>
            </div>

            <div className="total">
              <span className="sub">total:</span>
              <span className="total-price">
                {DEFAULT_CURRENCY} {total}
              </span>
            </div>

            <hr />
            <div className="cart-buttons d-flex align-items-center row">
              <Link to="/" className="col-md-6 desktop-cart-view">
                <button>Continue To Shopping</button>
              </Link>

              {total > 0 && (
                <div className="col-md-6 d-flex justify-content-md-end mt-3 mt-md-0">
                  <button onClick={checkOutHandler}>Checkout & Pay</button>
                </div>
              )}
            </div>
          </>
        )}
        <BestDealSection />
      </div>
    </>
  );
};

export default CartScreen;
