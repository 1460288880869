import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bestDealProductList } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import {
  DEFAULT_CURRENCY,
  IMAGE_CACHE_URL,
} from "../../Redux/Constants/GlobalConstant";
import QuantityInput from "../QuantityInput";

const BestDealSection = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.bestDealProductList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(bestDealProductList());
  }, [dispatch]);

  return (
    <div className="container">
      <div className="section">
        {/* <div className="row mobile-thumbnail"> */}
        <div className="col-lg-12 col-md-12 article">
          <h5>Best Deal of the Day</h5>
          <div className="best-deal-shopcontainer row">
            {loading ? (
              <div className="mb-5">
                <Loading />
              </div>
            ) : error ? (
              <Message key={1} variant="alert-danger">
                {error}
                
              </Message>
            ) : (
              <>
                {products?.map((product, i) => (
                  <div className="shop col-lg-2 col-md-3 col-sm-3" key={i}>
                    <div className="border-product">
                      <Link to={`/products/${product._id}`}>
                        <div className="shopBack">
                          {product.mrp_price - product.price > 0 && (
                            <div className="offer_strip">
                              {Math.floor(
                                ((product.mrp_price - product.price) /
                                  product.price) *
                                  100
                              )}{" "}
                              % off
                            </div>
                          )}
                          <img
                            src={IMAGE_CACHE_URL + product.image}
                            alt={product.name}
                          />
                        </div>
                      </Link>

                      <div className="shoptext">
                        <p>
                          <Link to={`/products/${product._id}`}>
                            {product.name} - {product?.unit}
                          </Link>
                        </p>
                        <div className="price-button-thumb-sec">
                          <h3>
                            {product.mrp_price - product.price > 0 && (
                              <span className="text-overline">
                                {DEFAULT_CURRENCY} {product.mrp_price}
                              </span>
                            )}
                            <span>
                              {DEFAULT_CURRENCY} {product.price}
                            </span>
                            {/* {product.mrp_price - product.price < 0 && (
                                      <span className="text-overline"> </span>
                                    )} */}
                          </h3>
                          <QuantityInput
                            productId={product._id}
                            renderAddButton={true}
                            qty={0}
                            max_order={
                              product?.max_order ? product?.max_order : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default BestDealSection;
