import React, { useState } from "react";

const MemberCard = (props) => {

    const { cardData } = props;
    console.log(cardData);
  //   const [selectedOption, setSelectedOption] = useState(0);

  //   const dispatch = useDispatch();

  return (
      <div class="Card-Group">
        <div class="Card">
          <div class="Logo">
          <img src="./images/user.png" alt="userprofileimage"/>
          </div>
          {/* <div class="Chip">
            <img
              Src="Https://Raw.Githubusercontent.Com/DasShounak/FreeUseImages/Main/Chip.Png"
              Alt="Chip"
            />
          </div> */}
          <div class="Number">1234 5678 9012 3456</div>
          <div class="Name">{cardData.name}</div>
          <div class="From">10/19</div>
          <div class="To">06/25</div>
          <div class="Ring"></div>
        </div>
      </div>
  );
};

export default MemberCard;
